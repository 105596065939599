import Button from "components/button";
import CustomerPortal from "components/customer-portal";
import { useSelector } from "react-redux";
import ChangePasswordModal from "components/change-password-modal";
import useSidebar from "components/sidebar/useSidebar";
import DeleteProfileModal from "components/delete-profile-modal";
import { EmailIcon, PhoneIcon } from "assets/img/icons";
import ResetPasswordModal from "components/reset-password-modal";
const SettingsView = () => {
  const userData = useSelector((state) => state.user);
  const companyDetails = useSelector(
    (state) => state.mobilePreview.companyDetails
  );
  const {
    active,
    customerPortalLink,
    endOfSubscription,
    showPaymentWidget,
    profileId,
  } = userData;
  const {
    company_logo,
    company_title_image,
    email,
    website,
    userName,
    companyDescription,
    companyAddress,
    companyAddressLink,
  } = companyDetails;
  const { changePassword, deleteProfile, resetPassword, handleLogOut } =
    useSidebar();
  const { isOpen, toggleModal } = changePassword;
  const { isOpenDelete, toggleModalDelete } = deleteProfile;
  const { isOpenReset, toggleModalReset } = resetPassword;

  return (
    <>
      {isOpen ? (
        <ChangePasswordModal onClose={() => toggleModal(false)} />
      ) : null}
      {isOpenDelete ? (
        <DeleteProfileModal onClose={() => toggleModalDelete(false)} />
      ) : null}
      {isOpenReset ? (
        <ResetPasswordModal onClose={() => toggleModalReset(false)} />
      ) : null}
      <div className="inner-container">
        <div className="detail-container">
          {showPaymentWidget ? (
            <CustomerPortal
              active={active}
              endOfSubscription={endOfSubscription}
              customerPortalLink={customerPortalLink}
              showPaymentWidget={showPaymentWidget}
            />
          ) : (
            <CustomerPortal
              active={active}
              endOfSubscription={endOfSubscription}
              customerPortalLink={customerPortalLink}
            />
          )}
          <div className="card general-information d-flex flex-col gap-2 text-left">
            <div className="d-flex justify-between gap-1 ">
              <h3>ACCOUNT INFORMATIONEN</h3>
            </div>
            <p>Profil ID: {profileId}</p>
            <Button
              onClick={() => toggleModal(true)}
              title="Passwort ändern"
              type="outline"
            />
            <Button
              onClick={() => toggleModalReset(true)}
              title="Passwort zurücksetzen"
              type="outline"
            />
            <Button onClick={handleLogOut} title="Abmelden" />
          </div>
          <div className="card general-information d-flex flex-col gap-2 text-left">
            <div className="d-flex justify-between gap-1 ">
              <h3>Profil löschen</h3>
            </div>
            <p>
              Möchten Sie ihr Profil endgültig löschen? <br></br>Stellen Sie
              sicher, dass Sie ihr Abonnement vor dem Löschen kündigen!
            </p>
            <Button
              onClick={() => toggleModalDelete(true)}
              title="Profil löschen"
              type="outline"
            />
          </div>
        </div>
        <div className="card general-information d-flex flex-col gap-2 text-left align-start">
          <div className="d-flex justify-between gap-1 ">
            <h3>KUNDENSERVICE</h3>
          </div>
          <div className="d-flex align-center gap-1">
            <EmailIcon />
            <p>office@surfjobs.at</p>
          </div>
          <div className="d-flex align-center gap-1">
            <PhoneIcon />
            <p>+43 664 1051888</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettingsView;
