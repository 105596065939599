import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { cloneDeep } from "lodash";
import {
  addInsightJobVideo,
  getVideos,
  deleteVideo,
  editVideo,
  deleteJobAddress,
  addPartialFile,
  getPossibleValues,
} from "services";
import { useSelector, useDispatch } from "react-redux";
import {
  addVideos,
  // toggleMobileLoading,
} from "store/reducers/mobilePreviewSlice";
import { runValidator } from "utils";
const useAddJob = (fileSizeError) => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const profileId = useSelector((state) => state.user.profileId);
  const [videoDetails, setVideoDetails] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [model, setModel] = useState({
    description: "",
    industryId: "",
    type: 0,
    name: "",
    videoDataBase64: "",
    salary: "",
    professionId: "",
    profileId,
    addresses: [
      {
        city: "",
        street: "",
        externalApplicationLink: "",
        zipCode: "",
        state: "",
      },
    ],
  });
  const [prevJobAddress, setPrevJobAddress] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState({});

  const INPUTS = {
    name: {
      rules: { required: true, maxLength: 50 },
      errorMessage: "Bitte Title eingeben mit max. 50 Zeichen",
    },
    videoDataBase64: {
      rules: { required: true },
      errorMessage: "Bitte ein MP4 Video hochladen",
    },
    description: {
      rules: { required: true },
      errorMessage: "Bitte eine Beschreibung einfügen",
    },
    addresses: {
      rules: { required: true },
      errorMessage: "Bitte eine Adresse einfügen",
    },
    salary: {
      rules: { required: true, decimal: true },
      errorMessage:
        "Bitte nur den Betrag mit Punkt als Dezimaltrennzeichen ohne Währungszeichen eingeben.",
    },
  };

  const isUploadButtonDisabled = () => {
    const {
      description,
      videoDataBase64,
      name,
      addresses,
      industryId,
      professionId,
    } = model;
    if (
      description !== "" &&
      (industryId !== "" || industryId !== "Branche wählen") &&
      (professionId !== "" || professionId !== "Lehrberuf wählen") &&
      videoDataBase64 !== "" &&
      name !== "" &&
      addresses.length > 0 &&
      !fileSizeError
    ) {
      const { state, zipCode, street } = addresses[0];
      if (
        (state !== "" &&
          state !== "Bundesland wählen" &&
          zipCode !== "" &&
          street !== "") ||
        prevJobAddress.length !== 0
      ) {
        return false;
      }
      console.log(model);
      return true;
    } else {
      return true;
    }
  };

  const isEditButtonDisabled = () => {
    const { description, name, industryId, professionId } = model;
    if (
      description !== "" &&
      name !== "" &&
      (industryId !== "" || industryId !== "Branche wählen") &&
      (professionId !== "" || professionId !== "Lehrberuf wählen") &&
      fileSizeError
    ) {
      console.log(fileSizeError);
      console.log(model);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getPossibleValues("industries").then((result) => {
      if (result) {
        const transformedIndustries = result.industries.map((industry) => ({
          title: industry.name,
          value: industry.id,
        }));
        setIndustries(transformedIndustries);
      } else {
        console.error("Industries data is not an array:", result);
      }
    });
    getPossibleValues("professions").then((result) => {
      if (result) {
        const transformedApprenticships = result.professions.map(
          (professionId) => ({
            title: professionId.name,
            value: professionId.id,
          })
        );
        setProfessions(transformedApprenticships);
      } else {
        console.error("Industries data is not an array:", result);
      }
    });
    if (location?.state) {
      const { video } = location?.state;
      setVideoDetails(video);
      setModel({
        ...model,
        name: video.jobName,
        videoDataBase64: video.videoDataBase64,
        description: video.jobDescription,
        industryId: video.industryId,
        salary: video.salary,
        professionId: video.professionId,
      });
      setPrevJobAddress([...video.addresses]);
      setIsUpdated(true);
    } else {
      // Clear model if navigating without a video detail
      setVideoDetails(null);
      setModel({
        description: "",
        industry: "",
        type: 0,
        name: "",
        professionId: "",
        profileId,
        videoDataBase64: "",
        salary: "",
        addresses: [
          {
            city: "",
            street: "",
            externalApplicationLink: "",
            zipCode: "",
            state: "",
          },
        ],
      });
    }
  }, [location]);

  const handleChangeModel = (name, value, index, isIndex) => {
    if (isIndex) {
      const payload = cloneDeep(model);
      payload.addresses[index][name] = value;
      setModel(cloneDeep(payload));
    } else {
      const payload = { ...model, [name]: value };
      setIsUpdated(true);
      setModel({ ...payload });
      console.log(payload);
    }
  };

  const addAddress = () => {
    const currentAddress = cloneDeep([...model.addresses]);
    currentAddress.push({
      city: "",
      street: "",
      externalApplicationLink: "",
      zipCode: "",
      state: "",
    });
    setModel({ ...model, addresses: cloneDeep(currentAddress) });
  };

  const closeAddress = () => {
    const currentAddress = cloneDeep([...model.addresses]);
    currentAddress.pop({
      city: "",
      street: "",
      externalApplicationLink: "",
      zipCode: "",
      state: "",
    });
    setModel({ ...model, addresses: cloneDeep(currentAddress) });
  };

  const validateAll = () => {
    let errors = {};
    for (let field in INPUTS) {
      let fieldErrors = runValidator(model[field], INPUTS[field].rules);
      let errorMessage = INPUTS[field].errorMessage;
      if (fieldErrors.length > "0") {
        errors[field] = errorMessage;
      }
    }
    return errors;
  };

  const validateEdit = () => {
    let errors = {};
    for (let field in INPUTS) {
      if (field !== "videoDataBase64") {
        let fieldErrors = runValidator(model[field], INPUTS[field].rules);
        let errorMessage = INPUTS[field].errorMessage;

        if (fieldErrors.length > "0") {
          errors[field] = errorMessage;
        }
      }
    }
    return errors;
  };

  const handleDeleteVideo = () => {
    setLoading(true);
    deleteVideo({ videoId: videoDetails.videoId }).then((result) => {
      if (result) {
        getVideos({ company: model.profileId, provideAddresses: true }).then(
          (result) => {
            dispatch(addVideos(result));
            setVideoDetails(false);
            setLoading(false);
            navigate("/");
          }
        );
      } else {
        setLoading(false);
        navigate("/");
      }
    });
  };

  const handleDeleteJobAddress = (addressId) => {
    deleteJobAddress({ addressId }).then((result) => {
      getVideos({ company: model.profileId, provideAddresses: true }).then(
        (result) => {
          const filteredResult = prevJobAddress.filter(
            (x) => x.addressId !== addressId
          );
          setPrevJobAddress(cloneDeep(filteredResult));
        }
      );
    });
  };
  const handleSubmit = async () => {
    let errors = validateAll();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    setProgress(1);
    let fileIds = await uploadVideoInParts(model.videoDataBase64);
    addInsightJobVideo({
      profileId: model.profileId,
      type: model.type,
      name: model.name,
      salary: model.salary,
      industryId: model.industryId,
      professionId: model.professionId,
      addresses: model.addresses,
      description: model.jobDescription,
      partialFileIds: fileIds,
    }).then((result) => {
      if (result?.videoId) {
        getVideos({ company: profileId, provideAddresses: true }).then(
          (result) => {
            dispatch(addVideos(result));
            setLoading(false);
            setProgress(100);
            navigate("/");
          }
        );
      } else {
        setLoading(false);
        navigate("/");
      }
    });
  };

  const handleEditVideo = async () => {
    let errors = validateEdit();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);

    let fileIds = [];
    if (model.videoDataBase64) {
      setProgress(1);
      fileIds = await uploadVideoInParts(model.videoDataBase64);
      editVideo({
        profileId: model.profileId,
        type: model.type,
        name: model.name,
        salary: model.salary,
        industryId: model.industryId,
        professionId: model.professionId,
        addresses: model.addresses,
        description: model.jobDescription,
        videoId: videoDetails.videoId,
        partialFileIds: fileIds,
      }).then((result) => {
        if (result?.videoId) {
          getVideos({ company: model.profileId, provideAddresses: true }).then(
            (result) => {
              dispatch(addVideos(result));
              setLoading(false);
              setProgress(100);
              navigate("/");
            }
          );
        } else {
          setLoading(false);
          navigate("/");
        }
      });
    } else {
      editVideo({
        ...model,
        videoId: videoDetails.videoId,
      }).then((result) => {
        if (result?.videoId) {
          getVideos({ company: model.profileId, provideAddresses: true }).then(
            (result) => {
              dispatch(addVideos(result));
              setLoading(false);
              setProgress(100);
              navigate("/");
            }
          );
        } else {
          setLoading(false);
          navigate("/");
        }
      });
    }
  };

  const uploadVideoInParts = async (base64Video) => {
    const partSize = 1024 * 512; // 1MB
    const totalParts = Math.ceil(base64Video.length / partSize);
    let start = 0;
    let end = partSize;
    let chunkNumber = 1;
    const tempPrtialFileIds = new Array(totalParts).fill(null);
    let currentProgress = 0;
    const maxConcurrentUploads = 5;
    let activeUploads = 0;

    const uploadPart = async (partialB64Data, reference) => {
      const result = await addPartialFile({ partialB64Data, reference });
      return result;
    };

    const uploadNextChunk = async () => {
      if (start >= base64Video.length) {
        return;
      }

      const partialB64Data = base64Video.substring(start, end);
      const currentChunkNumber = chunkNumber;

      start = end;
      end = start + partSize;
      chunkNumber++;

      activeUploads++;

      try {
        const result = await uploadPart(partialB64Data, currentChunkNumber);
        tempPrtialFileIds[currentChunkNumber - 1] = result.id; // Insert the result in the correct position
        console.log(tempPrtialFileIds);
        currentProgress++;
        setProgress((currentProgress / totalParts) * 100 - 1);
      } catch (error) {
        console.error("Error uploading partial files:", error);
      } finally {
        activeUploads--;
        // Try uploading the next chunk when one completes
        uploadNextChunk();
      }
    };

    // Start initial uploads
    for (
      let i = 0;
      i < maxConcurrentUploads && start < base64Video.length;
      i++
    ) {
      uploadNextChunk();
    }

    // Wait until all uploads are done
    while (activeUploads > 0) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Polling every 100ms
    }

    // Wait for a short duration to simulate smoother progression to 100%
    await new Promise((resolve) => setTimeout(resolve, 500));
    // setProgress(100);

    return tempPrtialFileIds;
  };

  return {
    navigate,
    model,
    prevJobAddress,
    handleChangeModel,
    addAddress,
    handleSubmit,
    loading,
    videoDetails,
    handleDeleteJobAddress,
    isUpdated,
    handleDeleteVideo,
    handleEditVideo,
    errors,
    isUploadButtonDisabled,
    isEditButtonDisabled,
    closeAddress,
    progress,
    industries,
    professions,
  };
};

export default useAddJob;
