import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { loginUser } from "services";
import { updateSessionHeader } from "services/axios";
import { runValidator } from "utils";
const useLoginView = () => {
  const navigate = useNavigate();
  const [model, setModel] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const INPUTS = {
    email: {
      rules: { required: true, email: true },
      errorMessage: "Bitte Email eingeben.",
    },
    password: {
      rules: { required: true },
      errorMessage: "Bitte Passwort eingeben.",
    },
  };
  const handleChange = (name, value) => {
    setModel({ ...model, [name]: value });
  };

  const validateAll = () => {
    let errors = {};

    for (let field in INPUTS) {
      let fieldErrors = runValidator(model[field], INPUTS[field].rules);
      let errorMessage = INPUTS[field].errorMessage;
      console.log(fieldErrors);
      if (fieldErrors.length > "0") {
        errors[field] = errorMessage;
      }
    }
    return errors;
  };
  const handleLoginUser = () => {
    const payload = { username: model.email, password: model.password };
    let errors = validateAll();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    loginUser(payload).then((result) => {
      setLoading(false);
      if (result?.session_id) {
        const { session_id } = result;
        localStorage.setItem("session", session_id);
        updateSessionHeader(session_id);
        window.location.reload();
      } else {
        setErrorMessage(result?.message);
      }
    });
  };
  return {
    navigate,
    saveModel: { model, setModal: handleChange },
    handleLoginUser,
    loading,
    errorMessage,
    errors,
  };
};
export default useLoginView;
