// hooks/useDeleteProfile.js

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router for navigation
import { deleteProfile } from "services";

export const useDeleteProfile = () => {
  const profileId = useSelector((state) => state.user.profileId);
  const navigate = useNavigate();

  const deleteProfileHandler = async () => {
    try {
      const result = await deleteProfile({ profileId });
      if (result.status === "success") {
        // Redirect to login screen after successful deletion
        navigate("/login");
      } else {
        // Handle error accordingly
        console.error(result.message);
        alert("An error occurred: " + result.message);
      }
    } catch (error) {
      console.error("An unexpected error occurred: ", error);
      alert("An unexpected error occurred");
    }
  };

  return { deleteProfileHandler };
};
