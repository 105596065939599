import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    companyDetails: {},
    videos: [],
    loading: true
};

export const mobilePreviewSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addVideos: (state, action) => {
            state.videos = action.payload;
        },
        addDetails: (state, action) => {
            state.companyDetails = action.payload;
            state.loading = false;
        },
        toggleMobileLoading: (state) => {
            state.loading = !state.loading;
        },
    },
});

// Action creators are generated for each case reducer function
export const { addVideos, addDetails, toggleMobileLoading } = mobilePreviewSlice.actions;

export default mobilePreviewSlice.reducer;
