import LoaadingImg from "assets/img/Loading-pana.png";
import { useState, useEffect } from "react";
import "assets/css/stylesheet.scss";
import { ApplicationRoutes, AuthorizationRoutes } from "routes";
import Sidebar from "components/sidebar";
import NotificationBar from "components/notification-bar";
import { NotAvailableIcon } from "assets/img/icons";
import { getCustomerProfile } from "services";
import { useDispatch } from "react-redux";
import { userLogin } from "store/reducers/userSlice";
import Loader from "components/loader";
function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(true);
  useEffect(() => {
    getCustomerProfile().then((result) => {
      if (Object.keys(result).length > 0) {
        const {
          active,
          contactCustomerSupport,
          customerPortalLink,
          endOfSubscription,
          paymentWidgetId,
          paymentWidgetKey,
          showPaymentWidget,
          profileId,
          clientReferenceId,
          stripeHtmlProductTable,
        } = result;
        if (profileId) {
          setIsLoggedIn(true);
          dispatch(userLogin(result));
          setUserData({
            active,
            contactCustomerSupport,
            customerPortalLink,
            endOfSubscription,
            paymentWidgetId,
            paymentWidgetKey,
            showPaymentWidget,
            profileId,
            clientReferenceId,
            stripeHtmlProductTable,
          });
        }
      } else {
        localStorage.removeItem("session");
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
  }, []);
  return (
    <>
      {loading ? (
        <>
          <div className="loader-screen">
            <div className="loading-image">
              <img src={LoaadingImg} />
            </div>
            Loading
            <Loader />
          </div>
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <>
              <Sidebar />
              <div className="main-content">
                {userData.active ? null : (
                  <NotificationBar
                    title="Ihr Profil ist nicht live!"
                    description="Aktivieren Sie Ihr Profil in den Einstellungen, um in unserer App angezeigt zu werden!"
                  />
                )}
                <ApplicationRoutes />
              </div>
            </>
          ) : (
            <AuthorizationRoutes />
          )}
        </>
      )}
      <div className="not-available-for-devices">
        <NotAvailableIcon />
        <p>
          {" "}
          Das Unternehmenskonto kann derzeit nur am Desktop bearbeitet werden.
        </p>
      </div>
    </>
  );
}

export default App;
