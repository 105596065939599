import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { registerUser } from "services";
import { runValidator } from "utils";
const useRegister = () => {
  const navigate = useNavigate();
  const [model, setModel] = useState({
    username: "",
    vatNumber: "",
    phoneNumber: "",
    email: "",
    password: "",
    repeatPassword: "",
    nrOfApprentiencies: "",
    terms: false,
    newsletter: false,
    dataProtectionResponsible: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const INPUTS = {
    email: {
      rules: { required: true, email: true },
      errorMessage: "Bitte Email eingeben",
    },
    password: {
      rules: { required: true },
      errorMessage: "Bitte Passwort eingeben",
    },
    repeatPassword: {
      rules: { required: true },
      errorMessage: "Bitte Passwort wiederholen",
    },
    vatNumber: {
      rules: { required: false },
      errorMessage: "Bitte UID eingeben",
    },
    phoneNumber: {
      rules: { required: true },
      errorMessage: "Bitte Telefonnummer eingeben",
    },
    username: {
      rules: { required: true },
      errorMessage: "Bitte Unternehmen eingeben",
    },
    terms: {
      rules: { required: true, checkbox: model.terms },
      errorMessage: "Bitte Bedingungen akzeptieren",
    },
    nrOfApprentiencies: {
      rules: { required: true, selectbox: true },
      errorMessage: "Bitte Anzahl an Lehrlingen im Unternhemen auswählen",
    },
  };

  const validateAll = () => {
    let errors = {};
    for (let field in INPUTS) {
      let fieldErrors = runValidator(model[field], INPUTS[field].rules);
      let errorMessage = INPUTS[field].errorMessage;
      if (fieldErrors.length > 0) {
        errors[field] = errorMessage;
      }
    }
    console.log(errors);
    return errors;
  };

  const handleSetModel = (name, value) => {
    setModel({ ...model, [name]: value });
  };
  const handleRegisterUser = () => {
    console.log(model.nrOfApprentiencies);
    let errors = validateAll();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    if (model.password !== model.repeatPassword) {
      setErrors({
        password: "",
        repeatPassword: "Passwort stimmt nicht überein",
      });
      return;
    }
    setLoading(true);
    const saveModel = { ...model };
    delete saveModel.repeatPassword;
    console.log(saveModel);
    registerUser(saveModel).then((result) => {
      console.log(result);
      setLoading(false);
      if (result?.userId) {
        const { session, userId } = result;
        if (session && userId) {
          localStorage.setItem("session", session);
          navigate("/");
        }
      } else {
        navigate("/");
      }
    });
  };
  return {
    navigate,
    saveModel: { model, setModel: handleSetModel },
    handleRegisterUser,
    loading,
    errors,
  };
};
export default useRegister;
