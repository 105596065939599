import { clonedeep } from "lodash";
import moment from "moment";
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (file.size > 250 * 1024 * 1024) {
      reject();
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const handleValidation = (e, errorsList, setErrorFunc, inputsList) => {
  let newErrors = runValidator(e.target.value, inputsList[e.target.name].rules);
  let errorMessage = inputsList[e.target.name].errorMessage;
  if (newErrors.length > 0) {
    setErrorFunc({ ...errorsList, [e.target.name]: errorMessage });
  } else {
    let uppdatedErrors = clonedeep(errorsList);
    delete uppdatedErrors[e.target.name];
    setErrorFunc({ ...uppdatedErrors });
  }
};

export function refreshPage() {
  window.location.reload();
}

export const validateAll = (inputsObject, validationsList) => {
  let errors = {};
  for (let field in validationsList) {
    let fieldErrors = runValidator(
      inputsObject[field],
      validationsList[field].rules
    );
    let errorMessage = validationsList[field].errorMessage;
    if (fieldErrors.length > 0) {
      errors[field] = errorMessage;
    }
  }
  return errors;
};

export const getErrors = (errors) => {
  let formattedErrors = {};
  for (const [key, value] of Object.entries(errors)) {
    formattedErrors[key] = value[0];
  }
  return formattedErrors;
};

export const runValidator = (value, validator) => {
  let d1 = moment(),
    d2 = moment();
  let errors = [];
  value = value === null || value === undefined ? "" : value.toString().trim();
  for (let rule in validator) {
    let error = "";
    switch (rule) {
      case "min":
        value = parseFloat(value);

        if (isNaN(value)) {
          error = rule.error ? rule.error : "Bitte eine Zahl eingeben. ";
          errors.push(error);
        } else if (value < parseFloat(validator[rule])) {
          error = rule.error
            ? rule.error
            : "Bitte mindestens eine Zahl von " +
              validator[rule] +
              " eingeben. ";
          errors.push(error);
        }
        break;
      case "max":
        value = parseFloat(value);
        if (isNaN(value)) {
          error = rule.error ? rule.error : "Bitte eine Zahl eingeben. ";
          errors.push(error);
        } else if (value > parseFloat(validator[rule])) {
          error = rule.error
            ? rule.error
            : "Bitte eine maximale Zahl von " + validator[rule] + "eingeben. ";
          errors.push(error);
        }
        break;
      case "minDate":
        d1 = moment(value);
        d2 = validator[rule];
        if (d1.isSameOrBefore(d2)) {
          error = rule.error ? rule.error : "Provide a valid date. ";
          errors.push(error);
        }
        break;
      case "maxDate":
        d1 = moment(value);
        d2 = validator[rule];
        if (d1.isSameOrAfter(d2)) {
          error = rule.error ? rule.error : "Provide a valid date. ";
          errors.push(error);
        }
        break;
      case "minLength":
        if (value.length < validator[rule]) {
          error = rule.error
            ? rule.error
            : "Bitte mindestens " + validator[rule] + " Zeichen eingeben. ";
          errors.push(error);
        }
        break;
      case "maxLength":
        if (value.length > validator[rule]) {
          error = rule.error
            ? rule.error
            : "Bitte maximal " + validator[rule] + " Zeichen eingeben. ";
          errors.push(error);
        }
        break;
      case "requiredIndex":
        if (value < 0) {
          error = rule.error ? rule.error : "Bitte einen Wert eingeben. ";
          errors.push(error);
        }
        break;
      case "required":
        if (validator.required === true && value.length < 1) {
          error = rule.error ? rule.error : "Bitte einen Wert eingeben. ";
          errors.push(error);
        }
        break;
      case "base64MP4Check":
        console.log(value.slice(0, 14));
        if (value.slice(0, 14) !== "data:video/mp4") {
          error = "Bitte MP4 Video hochladen.";
          errors.push(error);
        }
        break;
      case "email":
        if (
          value !== "" &&
          //eslint-disable-next-line
          value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/) === null
        ) {
          error = rule.error
            ? rule.error
            : "Bitte eine valide Email eingeben. ";
          errors.push(error);
        }
        break;
      case "url":
        if (
          value !== "" &&
          value.match(
            //eslint-disable-next-line
            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
          ) === null
        ) {
          error = rule.error
            ? rule.error
            : "Bitte einen validen Link eingeben. ";
          errors.push(error);
        }
        break;
      case "name":
        if (value !== "" && value.match(/^[a-zA-Z-\s]+$/) === null) {
          error = rule.error
            ? rule.error
            : "Bitte einen validen Namen eingeben. ";
          errors.push(error);
        }
        break;
      case "alphabets":
        if (value !== "" && value.match(/^[a-zA-Z]+$/) === null) {
          error = rule.error ? rule.error : "Bitte nur Buchstaben verwenden. ";
          errors.push(error);
        }
        break;
      case "alphanumeric":
        if (value !== "" && value.match(/^[a-zA-Z\s0-9]+$/) === null) {
          error = rule.error
            ? rule.error
            : "Buchstaben, Zahlen und Leerzeichen erlaubt. ";
          errors.push(error);
        }
        break;
      case "regex":
        let regex = validator[rule];
        if (regex.test(value) === false) {
          error = rule.error ? rule.error : "Bitte das Format anpassen. ";
          errors.push(error);
        }
        break;
      case "pattern":
        if (value !== "" && value.match(validator[rule]) === null) {
          error = rule.error ? rule.error : "Bitte das Format anpassen. ";
          errors.push(error);
        }
        break;
      case "integer":
        if (Number.isInteger(parseFloat(value)) === false) {
          error = "Bitte eine Nummer ohne Kommazahl eingeben. ";
          errors.push(error);
        }
        break;
      case "decimal":
        if (isNaN(value) === true) {
          error = rule.error ? rule.error : "Bitte das Format anpassen. ";
          errors.push(error);
        }
        break;
      case "checkbox":
        if (value !== "true") {
          error = "Bitte Box auswählen. ";
          errors.push(error);
        }
        break;
      case "selectbox":
        if (value === "Bitte auswählen") {
          error = "Bitte Anzahl an Lehrlingen im Unternhemen auswählen. ";
          errors.push(error);
        }
        break;

      default:
        break;
    }
  }
  return errors; // supposed to be errors array
};
