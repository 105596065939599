import PaymentWidget from "components/payment-widget";
import { useSelector } from "react-redux";

const PaymentsView = () => {
  const userData = useSelector((state) => state.user);
  const { paymentWidgetId, paymentWidgetKey, clientReferenceId } = userData;
  console.log(userData);
  return (
    <>
      <PaymentWidget
        paymentWidgetId={paymentWidgetId}
        paymentWidgetKey={paymentWidgetKey}
        clientReferenceId={clientReferenceId}
      />
    </>
  );
};
export default PaymentsView;
