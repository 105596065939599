import Button from "components/button";
import useDashboardView from "./useDashboardView";
import CustomerPortal from "components/customer-portal";
import MobilePreview from "components/mobile-preview";
import { useSelector } from "react-redux";
const DashboardView = () => {
  const userData = useSelector((state) => state.user);
  const {
    active,
    customerPortalLink,
    endOfSubscription,
    showPaymentWidget,
    profileId,
  } = userData;
  console.log(userData);
  const { navigate } = useDashboardView(profileId);
  return (
    <>
      <div className="inner-container">
        <div className="d-flex flex-col">
          <h3 className="text-center pb-2">PROFILVORSCHAU</h3>
          <MobilePreview />
        </div>
        <div className="detail-container">
          {/* {showPaymentWidget ? (
            <CustomerPortal
              active={active}
              endOfSubscription={endOfSubscription}
              customerPortalLink={customerPortalLink}
              showPaymentWidget={showPaymentWidget}
            />
          ) : (
            <CustomerPortal
              active={active}
              endOfSubscription={endOfSubscription}
              customerPortalLink={customerPortalLink}
            />
          )} */}
          <div className="text-center pb-2">&nbsp;</div>
          <div className="card general-information d-flex flex-col gap-3 text-left">
            <div className="d-flex justify-between gap-1 align-center">
              <h3>ALLGEMEINE INFORMATIONEN</h3>
              <Button
                onClick={() => navigate("/profile")}
                type="outline"
                title="Bearbeiten"
              />
            </div>
            <div className="d-flex justify-between gap-1 align-center">
              <h3>INSIGHT VIDEOS</h3>
              <Button
                onClick={() => navigate("/add-insight-video")}
                type="outline"
                title="Hochladen"
              />
            </div>
            <p>
              Teilen Sie faszinierende Einblicke in Ihr Unternehmen durch
              Insight Videos. Laden Sie hier neue Videos hoch oder bearbeiten
              Sie vorhandene Videos, um Ihre Firma optimal zu präsentieren.
              Nutzen Sie diese Gelegenheit, um potenziellen Bewerbern einen
              authentischen Einblick in Ihre Arbeitskultur und Werte zu geben.
              Ihre Videos können direkt in der Profilvorschau angesehen und
              bearbeitet werden.
            </p>
            <div className="d-flex justify-between  gap-1 align-center">
              <h3>JOB VIDEOS</h3>
              <Button
                onClick={() => navigate("/add-job")}
                type="outline"
                title="Hochladen"
              />
            </div>
            <p>
              Stellen Sie Ihre offenen Lehrstellen durch ansprechende Job Videos
              vor. Laden Sie neue Videos hoch oder bearbeiten Sie bestehende
              Videos, um Ihre Jobangebote attraktiv und informativ zu
              präsentieren. Nutzen Sie diese Plattform, um potenzielle Bewerber
              direkt anzusprechen und ihnen einen lebendigen Eindruck von den
              Arbeitsmöglichkeiten in Ihrem Unternehmen zu vermitteln. Ihre
              Videos können direkt in der Profilvorschau hochgeladen und
              angepasst werden.
            </p>

            <div className="d-flex justify-between gap-1 align-center">
              <h4>Tipps und Tricks zur Profilerstellung</h4>
              <Button
                title="Herunterladen"
                onClick={() => {
                  const pdfUrl = "Anleitung.pdf";
                  const link = document.createElement("a");
                  link.href = pdfUrl;
                  link.download = "Anleitung.pdf";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardView;
