import Button from "components/button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const CustomerPortal = ({
  active,
  endOfSubscription,
  customerPortalLink,
  showPaymentWidget,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="card text-left">
        <h3>KUNDENPORTAL</h3>
        <p className="text-left">
          Status:{"  "}
          <span className={active ? "status-active" : "status-inactive"}>
            {active ? "AKTIV" : "INAKTIV"}
          </span>
        </p>
        {endOfSubscription ? (
          <p className="text-left">
            Aktiv bis: {moment(endOfSubscription).format("DD.MM.YYYY")}
          </p>
        ) : (
          <div></div>
        )}
        <Button
          onClick={() =>
            !showPaymentWidget
              ? window.open(customerPortalLink, "_blank")
              : navigate("/payments")
          }
          type={!showPaymentWidget ? "outline" : "primary"}
          title={
            !showPaymentWidget ? "SUBSCRIPTION VERWALTEN" : "PROFIL AKTIVIEREN"
          }
        />
      </div>
    </>
  );
};
export default CustomerPortal;
