import "components/input-field/input-field.scss";
const SelectBox = (props) => {
  const {
    label,
    orientation,
    className,
    onChange,
    value,
    disabled,
    options,
    placeholder,
    isError,
    infoMessage,
  } = props;
  const selectProps = { disabled };
  if (value !== "") {
    selectProps.value = value;
  }
  return (
    <>
      <div className={`input-field-container ${orientation} ${className}`}>
        <div className="input-with-icon felx-row">
          {label ? <label className="input-label">{label}</label> : null}
          {infoMessage !== "" ? (
            <div className="icon-container">
              <span className="info-icon">&#9432;</span>
              <div className="tooltip-select">{infoMessage}</div>
            </div>
          ) : null}
        </div>
        <select
          {...selectProps}
          //   style={{ height: 31 }}
          onChange={(e) => onChange(e.target.value)}
          className="input-field"
        >
          <option>{placeholder}</option>
          {options.map((opt, index) => (
            <option
              key={`Select Box Option ${label} - ${index}`}
              value={opt.value}
            >
              {opt.title}
            </option>
          ))}
        </select>
        {isError ? <p className="error-message">{isError}</p> : null}
      </div>
    </>
  );
};

SelectBox.defaultProps = {
  orientation: "column",
  label: null,
  onChange: () => {},
  disabled: false,
  className: "",
  value: "",
  containerClass: "",
  placeholder: "Please select",
  options: [],
  infoMessage: "",
};
export default SelectBox;
