import Loader from "components/loader";
import { AddIcon, LocationIcon } from "assets/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MobilePreview = ({ activeVideoId = "" }) => {
  const navigate = useNavigate();
  const jobDetails =
    useSelector((state) => state.mobilePreview.companyDetails) ?? {};
  const loading = useSelector((state) => state.mobilePreview.loading);
  const videos = useSelector((state) => state.mobilePreview.videos) ?? [];
  const {
    company_logo = "",
    company_title_image = "",
    email = "",
    website = "",
    userName = "",
    companyDescription = "",
    companyAddress = "",
    companyAddressLink = "",
    companyAddresses = [],
  } = jobDetails;

  const openLink = (e, isWebsite) => {
    e.preventDefault();
    if (isWebsite) {
      window.open(website, "_blank");
    } else {
      window.open(`mailto:${email}`);
    }
  };

  const safeWebsite = website ?? "";
  const hrefLink = safeWebsite.startsWith("http")
    ? safeWebsite
    : `https://${safeWebsite}`;

  return (
    <div className="mobile-screen-dimension">
      {loading ? (
        <Loader />
      ) : (
        <div className="mobile-overflow-container">
          <div className="inner-mobile-container">
            <div className="d-flex align-center justify-center">
              <h4>FIRMA</h4>
              <div />
            </div>
            <div
              className="header-image"
              onClick={() => navigate("/profile", { replace: true })}
            >
              {company_title_image ? (
                <img
                  onError={(e) => {
                    e.target.src =
                      "https://homethrive.com/wp-content/plugins/elementor/assets/images/placeholder.png";
                  }}
                  src={company_title_image}
                  alt="Firmen Titelbild"
                />
              ) : (
                <img
                  src="https://homethrive.com/wp-content/plugins/elementor/assets/images/placeholder.png"
                  alt="Placeholder Firmen Titelbild"
                />
              )}
            </div>
            <div
              className="company-details mobile-card"
              onClick={() => navigate("/profile", { replace: true })}
            >
              <div className="d-flex align-center gap-1">
                <div className="company-logo">
                  {company_logo ? (
                    <img src={company_logo} alt="Firmen Logo" />
                  ) : (
                    <img
                      src="https://docketly.com/wp-content/uploads/2019/12/logo-placeholder.png"
                      alt="Placeholder Firmen Logo"
                    />
                  )}
                </div>
                <h5>{userName}</h5>
              </div>
              {companyDescription && (
                <p className="company-description">{companyDescription}</p>
              )}
            </div>
            {companyAddress && (
              <div
                className="mobile-card d-flex justify-between"
                onClick={() => navigate("/profile", { replace: true })}
              >
                <div className="d-flex flex-col">
                  <h5>ADRESSE</h5>
                  <p className="company-description pt-1">
                    {companyAddresses[0]?.address ?? ""}
                  </p>
                </div>
                <a
                  href={companyAddressLink}
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  <LocationIcon width={24} />
                </a>
              </div>
            )}
            <div className="d-flex justify-between gap-1">
              <a
                href={hrefLink}
                target="_blank"
                rel="noreferrer"
                className="mobile-button mobile-primary"
              >
                Webseite
              </a>
              <a
                onClick={(e) => openLink(e, false)}
                className="mobile-button mobile-primary"
              >
                E-Mail
              </a>
              <a className="mobile-button mobile-primary">Telefon</a>
            </div>
            <div className="d-flex flex-col gap-1">
              <div className="d-flex align-center justify-between">
                <h5>EINBLICKE INS UNTERNEHMEN</h5>
                <div
                  onClick={() =>
                    navigate("/add-insight-video", { replace: true })
                  }
                  className="add-button"
                >
                  <p>NEUES VIDEO</p>
                  <AddIcon />
                </div>
              </div>
              <div className="videos-container">
                {videos
                  .filter((x) => x.videoType !== 0)
                  .map((vid, index) => (
                    <div
                      onClick={() =>
                        navigate("/add-insight-video", {
                          state: { video: vid },
                        })
                      }
                      className={`video-item ${
                        vid.videoId === activeVideoId ? "active" : ""
                      }`}
                      key={`Mobile Preview Video Item ${index}`}
                    >
                      <img src={vid.thumbnail} alt="Thumbnail Video" />
                      <div className="video-details">
                        <p className="job-name">{vid.jobName}</p>
                        <p className="company-name">{vid.userName}</p>
                      </div>
                    </div>
                  ))}
                {videos.filter((x) => x.videoType !== 0).length === 0 && null}
              </div>
            </div>
            <div className="d-flex flex-col gap-1">
              <div className="d-flex align-center justify-between">
                <h5>LEHRSTELLEN</h5>
                <div
                  onClick={() => {
                    navigate("/add-job");
                  }}
                  className="add-button"
                >
                  <p>NEUES VIDEO</p>
                  <AddIcon />
                </div>
              </div>
              <div className="videos-container">
                {videos
                  .filter((x) => x.videoType === 0)
                  .map((vid, index) => (
                    <div
                      onClick={() =>
                        navigate("/add-job", { state: { video: vid } })
                      }
                      className={`video-item ${
                        vid.videoId === activeVideoId ? "active" : ""
                      }`}
                      key={`Mobile Preview Video Item ${index}`}
                    >
                      <img src={vid.thumbnail} alt="Thumbnail Video" />
                      <div className="video-details">
                        <p className="job-name">{vid.jobName}</p>
                        <p className="company-name">{vid.userName}</p>
                      </div>
                    </div>
                  ))}
                {videos.filter((x) => x.videoType === 0).length === 0 && null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobilePreview;
