import axios from "./axios";
export const loginUser = (data) => {
  let result = axios
    .post(`/no-auth/v1/login`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      return { status: error.status, message: error.message };
    });
  return result;
};

export const registerUser = (data) => {
  let result = axios
    .post(`/no-auth/v1/createCompanyProfileAndUser`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetretten beim Registrieren."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const forgotPassword = (data) => {
  let result = axios
    .post(`/no-auth/v1/resetPassword`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetretten beim Zurücksetzen des Passworts."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const deleteProfile = (data) => {
  let result = axios
    .post(`/auth/v1/deleteProfile`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetretten beim Löschen des Profils."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const getPossibleValues = (type, data) => {
  let result = axios
    .get(`/no-auth/v1/getPossibleValues?type=${type}`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(error.message ? error.message : "Serverfehler aufgetretten.");
      return { status: error.status, message: error.message };
    });
  return result;
};
