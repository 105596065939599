import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const {
        profileId,
        contactCustomerSupport,
        customerPortalLink,
        endOfSubscription,
        paymentWidgetId,
        paymentWidgetKey,
        showPaymentWidget,
        active,
        clientReferenceId,
        stripeHtmlProductTable,
      } = action.payload;
      state.isLoggedIn = true;
      state.profileId = profileId;
      state.contactCustomerSupport = contactCustomerSupport;
      state.customerPortalLink = customerPortalLink;
      state.endOfSubscription = endOfSubscription;
      state.paymentWidgetId = paymentWidgetId;
      state.paymentWidgetKey = paymentWidgetKey;
      state.showPaymentWidget = showPaymentWidget;
      state.active = active;
      state.clientReferenceId = clientReferenceId;
      state.stripeHtmlProductTable = stripeHtmlProductTable;
    },
    userLogOut: (state) => {
      state.isLoggedIn = false;
      state.userData = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { userLogin, userLogOut } = userSlice.actions;

export default userSlice.reducer;
