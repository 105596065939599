import { CancelIcon } from "assets/img/icons";
import InputField from "components/input-field";
import Button from "components/button";
import useOnclickOutside from "react-cool-onclickoutside";
import useChangePassword from "./useChangePassword";
const ChangePasswordModal = ({ onClose }) => {
  const ref = useOnclickOutside(() => {
    onClose();
  });
  const { saveModal, isButtonDisable, handleSubmit, loading, errors } =
    useChangePassword(onClose);
  const {
    modal: { oldPassword, newPassword, repeatPassword },
    onChangeModal,
  } = saveModal;
  return (
    <>
      <div className="modal-outer-container">
        <div ref={ref} className="modal">
          <div className="modal-header">
            <h3>Passwort ändern</h3>
            <div onClick={onClose} className="cursor-pointer">
              <CancelIcon />
            </div>
          </div>
          <div className="modal-body">
            <InputField
              value={oldPassword}
              isError={errors?.oldPassword}
              type="password"
              onChange={(value) => onChangeModal("oldPassword", value)}
              placeholder="********"
              label="Altes Password"
            />
            <InputField
              isError={errors?.newPassword}
              value={newPassword}
              onChange={(value) => onChangeModal("newPassword", value)}
              type="password"
              placeholder="********"
              label="Neues Passwort"
            />
            <InputField
              isError={errors?.repeatPassword}
              value={repeatPassword}
              onChange={(value) => onChangeModal("repeatPassword", value)}
              type="password"
              placeholder="********"
              label="Neues Passwort wiederholen"
            />
            <Button
              loading={loading}
              onClick={handleSubmit}
              disabled={isButtonDisable()}
              title="Speichern"
            />
          </div>
        </div>
      </div>
    </>
  );
};
ChangePasswordModal.defaultProps = {
  onClose: () => {},
};
export default ChangePasswordModal;
