import { useState } from "react";
import { editPassword } from "services";
import { runValidator } from "utils";
const useChangePassword = (onClose) => {
  const [saveModal, setSaveModal] = useState({
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const INPUTS = {
    oldPassword: {
      rules: { required: true },
      errorMessage: "Altes Passwort eingeben",
    },
    newPassword: {
      rules: { required: true },
      errorMessage: "Neues Passwort eingeben",
    },
    repeatPassword: {
      rules: { required: true },
      errorMessage: "Neues Passwort bestätigen",
    },
  };
  const validateAll = () => {
    let errors = {};
    for (let field in INPUTS) {
      let fieldErrors = runValidator(saveModal[field], INPUTS[field].rules);
      let errorMessage = INPUTS[field].errorMessage;
      if (fieldErrors.length > "0") {
        errors[field] = errorMessage;
      }
    }
    return errors;
  };
  const handleChange = (name, value) => {
    setSaveModal({
      ...saveModal,
      [name]: value,
    });
  };
  const isButtonDisable = () => {
    const { oldPassword, newPassword, repeatPassword } = saveModal;
    const isDisable =
      oldPassword === "" || newPassword === "" || repeatPassword === "";
    return isDisable;
  };
  const handleSubmit = () => {
    let errors = validateAll();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    if (saveModal.newPassword !== saveModal.repeatPassword) {
      setErrors({
        newPassword: "Passwort stimmt nicht überein",
        repeatPassword: "Passwort stimmt nicht überein",
      });
      return;
    }
    setLoading(true);
    const payload = { ...saveModal };
    delete payload.repeatPassword;
    editPassword(payload).then((result) => {
      setLoading(false);
      if (result.message === "Username or password is wrong.") {
        setErrors({
          oldPassword: "Altes Passwort falsch",
        });
      } else if (result.status === "error") {
        alert(
          "Ein Fehler ist beim zurücksetzen passiert.\nVersuche es zu einem späteren Zeitpunkt wieder!"
        );
      } else {
        onClose();
      }
    });
  };
  return {
    saveModal: { modal: saveModal, onChangeModal: handleChange },
    isButtonDisable,
    handleSubmit,
    loading,
    errors,
  };
};
export default useChangePassword;
