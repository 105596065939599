import "./button.scss";
import Loader from "components/loader";
const Button = (props) => {
  const { title, className, onClick, type, loading, disabled, icon } = props;
  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`button ${type} ${className}`}
      >
        {loading ? <Loader size={16} /> : null}
        {icon ? icon : null}
        <span>{title}</span>
      </button>
    </>
  );
};
Button.defaultProps = {
  title: "",
  className: "",
  icon: false,
  onClick: () => {},
  type: "primary",
  loading: false,
  disabled: false,
};
export default Button;
