import "components/input-field/input-field.scss";
const TextArea = (props) => {
  const {
    className,
    label,
    placeholder,
    onChange,
    disabled,
    inputStyle,
    rows,
    value,
    isError,
    infoMessage,
  } = props;
  return (
    <>
      <div className={`input-field-container ${className}`}>
        <div className="input-with-icon felx-row">
          {label ? <label className="input-label">{label}</label> : null}
          {infoMessage !== "" ? (
            <div className="icon-container">
              <span className="info-icon">&#9432;</span>
              <div className="tooltip">{infoMessage}</div>
            </div>
          ) : null}
        </div>
        <textarea
          rows={rows}
          value={value}
          className="input-field"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          style={inputStyle}
        />
        {isError ? <p className="error-message">{isError}</p> : null}
      </div>
    </>
  );
};
TextArea.defaultProps = {
  label: null,
  rows: 5,
  onChange: () => {},
  className: "",
  value: "",
  inputStyle: {},
  disabled: false,
  placeholder: "",
  isError: false,
  infoMessage: "",
};
export default TextArea;
