import { CancelIcon } from "assets/img/icons";
import Button from "components/button";
import useOnclickOutside from "react-cool-onclickoutside";
import { useDeleteProfile } from "./useDeleteProfile";

const DeleteProfileModal = ({ onClose }) => {
  const { deleteProfileHandler } = useDeleteProfile();
  const ref = useOnclickOutside(() => {
    onClose();
  });

  return (
    <>
      <div className="modal-outer-container">
        <div ref={ref} className="modal">
          <div className="modal-header">
            <h3>Profil löschen</h3>
            <div onClick={onClose} className="cursor-pointer">
              <CancelIcon />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Wollen Sie ihr Profil endgültig löschen? <br />
              ACHTUNG: Kündigen Sie die Subscription bevor Sie das Profil
              löschen!
            </p>
            <div className="d-flex flex-row justify-center gap-1">
              <Button
                onClick={deleteProfileHandler}
                title="JA"
                type="outline"
              />
              <Button onClick={onClose} title="NEIN" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
DeleteProfileModal.defaultProps = {
  onClose: () => {},
};
export default DeleteProfileModal;
