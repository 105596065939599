import { useState, useRef, useEffect } from "react";
import { PlayIcon, PauseIcon, FullScreenIcon } from "assets/icons";

const VideoCustomControls = ({ refElem }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [playingValue, setPlayingValue] = useState(0);
  const intervalRef = useRef(null);
  useEffect(() => {
    const video = refElem.current;
    if (video) {
      video.addEventListener("ended", handleVideoEnd);
    }
    return () => {
      if (video) {
        video.removeEventListener("ended", handleVideoEnd);
      }
      stopProgressingLoop();
    };
  }, [refElem]);
  const handleVideoEnd = () => {
    setPlaying(false);
    setPlayingValue(0);
    stopProgressingLoop();
  };
  const handleVideoPlay = () => {
    if (!isPlaying) {
      refElem.current.play();
      startProgressLoop();
    } else {
      refElem.current.pause();
      stopProgressingLoop();
    }
    setPlaying(!isPlaying);
  };

  const startProgressLoop = () => {
    if (intervalRef?.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      updateProgress();
    }, 1000);
  };

  const updateProgress = () => {
    if (refElem?.current) {
      const value =
        (refElem?.current?.currentTime / refElem?.current?.duration) * 100;
      setPlayingValue(value);
    }
  };

  const stopProgressingLoop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const goToFullScreen = () => {
    const elem = refElem.current;
    if (elem) {
      //checking if ref was initiated
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  };

  return (
    <>
      <div className="custom-controls">
        <button onClick={handleVideoPlay} className="play-button">
          {!isPlaying ? <PlayIcon width={20} /> : <PauseIcon height={20} />}
        </button>
        <input
          value={playingValue}
          max={100}
          min={0}
          className="video-range"
          type="range"
          disabled={true}
        />
        <button onClick={goToFullScreen} className="play-button">
          <FullScreenIcon width={20} />
        </button>
      </div>
    </>
  );
};
export default VideoCustomControls;
