import Button from "components/button";
import InputField from "components/input-field";
import TextArea from "components/text-area";
import UploadButton from "components/upload-button";
import useProfileView from "./useProfileView";
import MobilePreview from "components/mobile-preview";
import SelectBox from "components/select-box";
import { regionOptions } from "views/add-job";
const ProfileView = () => {
  const { loading, navigate, model, handleSubmit, errors, industries } =
    useProfileView();
  const {
    saveModel: {
      description,
      email,
      username,
      website,
      phoneNumber,
      companyIndustryId,
      companyAddresses,
    },
    handleChangeModel,
  } = model;
  return (
    <>
      <div className="inner-container">
        <MobilePreview />
        <div className="detail-container">
          <div className="general-information d-flex flex-col gap-1">
            <h3>ALLGEMEINE INFORMATIONEN</h3>
            <div className="d-flex w-100 gap-5 mt-20">
              <div className="d-flex w-100 flex-col justify-between gap-2">
                <InputField
                  onChange={(value) => handleChangeModel("username", value)}
                  value={username}
                  isError={errors?.username}
                  label="Unternehmen"
                />
                <SelectBox
                  onChange={(value) =>
                    handleChangeModel("companyIndustryId", value)
                  }
                  placeholder="Branche wählen"
                  label="Branche"
                  value={companyIndustryId}
                  options={industries}
                />
                <InputField
                  onChange={(value) => handleChangeModel("website", value)}
                  isError={errors?.website}
                  value={website}
                  label="Link zur Webseite"
                  infoMessage="Bitte geben Sie den Link zu Ihrer Karriere- oder Lehrlingsseite an. Interessenten werden in Ihrem Profil zu dieser Seite weitergeleitet."
                />
                <InputField
                  onChange={(value) => handleChangeModel("email", value)}
                  isError={errors?.email}
                  value={email}
                  label="E-Mail"
                  infoMessage="Bitte geben Sie die E-Mail-Adresse an, über die Interessenten Sie kontaktieren können."
                />
                <InputField
                  onChange={(value) => handleChangeModel("phoneNumber", value)}
                  value={phoneNumber}
                  label="Telefonnummer"
                  infoMessage="Bitte geben Sie eine Telefonnummer an, über die Interessenten Sie kontaktieren können."
                />
                <div className="d-flex flex-col gap-1">
                  {companyAddresses &&
                    companyAddresses.map((item, index) => (
                      <>
                        <InputField
                          onChange={(value) =>
                            handleChangeModel("street", value, true, index)
                          }
                          className="w-per-100"
                          value={item.street}
                          placeholder="Adresse"
                          label="Standort"
                        />
                        <div className="d-flex gap-1">
                          <InputField
                            onChange={(value) =>
                              handleChangeModel("city", value, true, index)
                            }
                            className="w-per-50"
                            value={item.city ?? ""}
                            placeholder="Stadt"
                          />
                          <InputField
                            onChange={(value) =>
                              handleChangeModel("zipCode", value, true, index)
                            }
                            className="w-per-50"
                            value={item.zipCode ?? ""}
                            placeholder="PLZ"
                          />
                        </div>
                        <SelectBox
                          placeholder="Bundesland wählen"
                          className="w-per-100"
                          value={item.state}
                          onChange={(value) =>
                            handleChangeModel("state", value, true, index)
                          }
                          options={regionOptions}
                        />
                      </>
                    ))}
                </div>

                <TextArea
                  onChange={(value) => handleChangeModel("description", value)}
                  value={description}
                  rows={5}
                  label="Unternehmensbeschreibung"
                  infoMessage="Bitte geben Sie hier eine kurze Beschreibung Ihres Unternehmens ein."
                />
              </div>
              <div className="d-flex w-100 h-per-100 flex-col justify-between gap-2">
                <div className="d-flex flex-col gap-2">
                  <UploadButton
                    onChange={(result) =>
                      handleChangeModel("titleImageBase64", result)
                    }
                    label="Titelbild"
                    fileType="image/png, image/jpeg"
                    infoMessage="Bitte laden Sie ein Titelbild hoch, das Ihr Unternehmen repräsentiert. Das Bild sollte im Querformat sein, um richtig angezeigt zu werden"
                  />
                  <UploadButton
                    onChange={(result) =>
                      handleChangeModel("profileImageBase64", result)
                    }
                    label="Profilbild"
                    fileType="image/png, image/jpeg"
                    infoMessage="Bitte laden Sie Ihr Unternehmenslogo als Profilbild hoch. 
                    Wichtig: Das Logo sollte quadratisch sein und eine Hintergrundfarbe besitzen, um richtig angezeigt zu werden. "
                  />
                  <div className="d-flex gap-2">
                    <Button
                      loading={loading}
                      onClick={handleSubmit}
                      title="&nbsp;&nbsp;&nbsp;SPEICHERN&nbsp;&nbsp;&nbsp;"
                    />
                    <Button
                      onClick={() => navigate("/")}
                      type="outline"
                      title="BEENDEN"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileView;
