import axios from "./axios";
export const getCustomerProfile = (data) => {
  let result = axios
    .get(`/auth/v1/getCustomerProfileStatus`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten bei der Abfrage des Profils."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const editCustomerProfile = (data) => {
  let result = axios
    .post(`/auth/v1/editCustomerProfile`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim bearbeiten des Profils."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const getJobDetails = (data) => {
  let result = axios
    .get(`/auth/v1/getJobDetail`, data)
    .then((res) => {
      console.log(res?.data);
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim Abruf der Job Details."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const addInsightJobVideo = (data) => {
  let result = axios
    .post(`/auth/v1/addVideo`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error
          ? error.message
          : "Serverfehler aufgetreten beim hochladen des Videos."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const addPartialFile = (data) => {
  let result = axios
    .post(`/auth/v1/addPartialFile`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim hochladen des Videos in Chunks."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const getProducts = (data) => {
  let result = axios
    .get(`/auth/v1/getProducts`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(error.message ? error.message : "Serverfehler aufgetreten.");
      return { status: error.status, message: error.message };
    });
  return result;
};

export const getVideos = (data) => {
  let result = axios
    .post(`/auth/v1/getVideos`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim Abrufen der Videos."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const editPassword = (data) => {
  let result = axios
    .post(`/auth/v1/editPassword`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim bearbeiten des Passwortes."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const editVideo = (data) => {
  let result = axios
    .post(`/auth/v1/editVideo`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim bearbeiten des Videos."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const deleteVideo = (data) => {
  let result = axios
    .post(`/auth/v1/deleteVideo`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim löschen des Videos."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};

export const deleteJobAddress = (data) => {
  let result = axios
    .post(`/auth/v1/deleteAddress`, data)
    .then((res) => {
      return res?.data ? res.data : Promise.reject(res.response.data);
    })
    .catch((error) => {
      alert(
        error.message
          ? error.message
          : "Serverfehler aufgetreten beim löschen der Adresse."
      );
      return { status: error.status, message: error.message };
    });
  return result;
};
