const PaymentWidget = ({
  paymentWidgetId,
  paymentWidgetKey,
  clientReferenceId,
}) => {
  return (
    <>
      <div className="stripe-payment-widget">
        <stripe-pricing-table
          pricing-table-id={paymentWidgetId}
          publishable-key={paymentWidgetKey}
          client-reference-id={clientReferenceId}
        ></stripe-pricing-table>
      </div>
    </>
  );
};

export default PaymentWidget;
