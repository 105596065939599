import Logo from "assets/img/logo.png";
import InputField from "components/input-field";
import Button from "components/button";
import useRegister from "./useRegister";
import SelectBox from "components/select-box";
const RegisterScreen = () => {
  const { navigate, saveModel, handleRegisterUser, loading, errors } =
    useRegister();
  const {
    model: {
      email,
      password,
      username,
      vatNumber,
      phoneNumber,
      repeatPassword,
      nrOfApprentiencies,
      terms,
      newsletter,
      dataProtectionResponsible,
    },
    setModel,
  } = saveModel;
  return (
    <>
      <div className="authorization-container-view">
        <div className="logo-container">
          <img src={Logo} alt="SURFJobs Logo" />
        </div>
        <div className="container register-container">
          <div className="left-container" />
          <div className="right-container pt-4 pb-4">
            <h4 className="heading">REGISTRIEREN</h4>
            <div className="d-flex gap-3">
              <div className="d-flex flex-col w-40 gap-1 justify-center align-center">
                <InputField
                  disabled={loading}
                  value={username}
                  onChange={(value) => setModel("username", value)}
                  className="w-40"
                  isError={errors?.username}
                  label="Unternehmen"
                  type="text"
                />
                <InputField
                  disabled={loading}
                  value={vatNumber}
                  onChange={(value) => setModel("vatNumber", value)}
                  isError={errors?.vatNumber}
                  className="w-40"
                  label="UID Nummer"
                  type="text"
                  infoMessage="Bitte geben Sie Ihre Umsatzsteuer-Identifikationsnummer (UID-Nummer) an, falls vorhanden. Diese Angabe ist erforderlich für die Rechnungsstellung und steuerliche Abwicklung."
                />
                <InputField
                  disabled={loading}
                  value={phoneNumber}
                  onChange={(value) => setModel("phoneNumber", value)}
                  isError={errors?.phoneNumber}
                  className="w-40"
                  label="Telefonnummer"
                  type="text"
                  infoMessage="Bitte geben Sie eine Telefonnummer an, unter der wir Sie für Rückfragen erreichen können.
                  Diese Nummer wird ausschließlich für die Kommunikation zwischen Ihnen und SURFjobs verwendet und nicht für die Kontaktaufnahme mit BewerberInnen."
                />
                <InputField
                  disabled={loading}
                  value={email}
                  onChange={(value) => setModel("email", value)}
                  isError={errors?.email}
                  className="w-40"
                  label="Email"
                  type="email"
                  infoMessage="Bitte geben Sie eine E-Mail-Adresse für Rückfragen mit SURFjobs und als zukünftige Login-Adresse an. 
                  Wichtig: Verwenden Sie nicht die Kontakt-E-Mail, über die Sie mit BewerberInnen kommunizieren."
                />
                <InputField
                  disabled={loading}
                  value={password}
                  onChange={(value) => setModel("password", value)}
                  isError={errors?.password}
                  className="w-40"
                  label="Passwort"
                  type="password"
                  infoMessage="Verwenden Sie ein starkes Passwort."
                />
                <InputField
                  disabled={loading}
                  isError={errors?.repeatPassword}
                  value={repeatPassword}
                  onChange={(value) => setModel("repeatPassword", value)}
                  className="w-40"
                  label="Passwort wiederholen"
                  type="password"
                />
                <InputField
                  disabled={loading}
                  value={dataProtectionResponsible}
                  onChange={(value) =>
                    setModel("dataProtectionResponsible", value)
                  }
                  isError={errors?.dataProtectionResponsible}
                  className="w-40"
                  label="Datenschutzbeauftragter"
                  type="dataProtectionResponsible"
                  infoMessage="Bitte geben Sie die Kontaktdaten Ihres Datenschutzbeauftragten an, falls Ihr Unternehmen einen solchen ernannt hat. Diese Information ist wichtig für unsere Auftragsdatenverarbeitung sowie zur Einhaltung der Datenschutz-Grundverordnung (DSGVO) und anderer datenschutzrechtlicher Bestimmungen."
                />
              </div>
              <SelectBox
                className="w-40 "
                value={nrOfApprentiencies}
                placeholder="Bitte auswählen"
                onChange={(value) => setModel("nrOfApprentiencies", value)}
                isError={errors?.nrOfApprentiencies}
                options={apprentienciesOptions}
                label="Lehrlinge im Unternehmen"
                infoMessage="Bitte geben Sie die Anzahl der derzeit beschäftigten Lehrlinge in Ihrem Unternehmen an. Diese Angabe kann nachträglich nicht geändert werden und wird von SURFjobs überprüft. "
              />
            </div>
            <div className="d-flex flex-col gap-1 ">
              <div className="d-flex">
                <input
                  checked={terms}
                  type="checkbox"
                  className="me-2"
                  label="terms"
                  onChange={(event) => setModel("terms", event.target.checked)}
                ></input>
                <p className="hrefLink ms-8">
                  Einverständnis zu{" "}
                  <a
                    href="https://www.surfjobs.at/nutzungsbedingungen"
                    target="_blank"
                    className="hrefLink color-primary"
                    rel="noreferrer"
                  >
                    Nutzungsbedingungen
                  </a>{" "}
                  und{" "}
                  <a
                    href="https://www.surfjobs.at/datenschutz"
                    target="_blank"
                    className="hrefLink color-primary"
                    rel="noreferrer"
                  >
                    Datenschutzbestimmungen
                  </a>
                  .
                </p>
                {errors.terms && (
                  <p className="error-message">{errors.terms}</p>
                )}
              </div>
              <div className="d-flex">
                <input
                  checked={newsletter}
                  type="checkbox"
                  className="me-2"
                  label="terms"
                  onChange={(event) =>
                    setModel("newsletter", event.target.checked)
                  }
                ></input>
                <p className="hrefLink ms-8">
                  Ich möchte über Neuigkeiten von SURFjobs per Newsletter
                  informiert werden.
                </p>
                {errors.terms && (
                  <p className="error-message">{errors.terms}</p>
                )}
              </div>
            </div>
            <div className="d-flex flex-col gap-1 ">
              <Button
                disabled={loading}
                loading={loading}
                onClick={handleRegisterUser}
                title="REGISTRIEREN"
              />
              <p onClick={() => navigate("/")} className="hrefLink ">
                Sie haben bereits einen Account?
                <span className="hrefLink color-primary ms-8">Anmelden</span>
              </p>
              <div className="d-flex flex-ro gap-1">
                <div>
                  <a
                    className="color-black text-center size-2 pb-1"
                    href="https://www.surfjobs.at/impressum"
                    target="_blank"
                    rel="noreferrer"
                  >
                    IMPRESSUM
                  </a>
                </div>
                <div>
                  <a
                    className=" color-black text-center size-2 text-black pb-1"
                    href="https://www.surfjobs.at/datenschutz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DATENSCHUTZ
                  </a>{" "}
                </div>
                <div>
                  <a
                    className="color-black text-center size-2 text-black pb-1"
                    href="https://www.surfjobs.at/nutzungsbedingungen"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NUTZUNGSBEDINGUNGEN
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RegisterScreen;

const apprentienciesOptions = [
  { title: "1-5", value: "1-5" },
  { title: "6-19", value: "6-19" },
  { title: "20-50", value: "20-50" },
  { title: "50+", value: "50+" },
];
