import { useState } from "react";
import { useNavigate } from "react-router-dom";
const useSidebar = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [isSettings, setIsSettings] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalReset, setOpenModalReset] = useState(false);
  const handleLogOut = () => {
    delete localStorage.removeItem("session");
    window.open(window.location.origin, "_self");
  };
  const handleRouteClickHome = () => {
    navigate("/");
    setIsHome(true);
    setIsSettings(false);
  };
  const handleRouteClickSettings = () => {
    navigate("/settings");
    setIsHome(false);
    setIsSettings(true);
  };
  return {
    changePassword: { isOpen: openModal, toggleModal: setOpenModal },
    deleteProfile: {
      isOpenDelete: openModalDelete,
      toggleModalDelete: setOpenModalDelete,
    },
    resetPassword: {
      isOpenReset: openModalReset,
      toggleModalReset: setOpenModalReset,
    },
    handleLogOut,
    handleRouteClickHome,
    isHome,
    handleRouteClickSettings,
    isSettings,
  };
};

export default useSidebar;
