import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { editCustomerProfile, getPossibleValues } from "services";
import { useSelector, useDispatch } from "react-redux";
import { addDetails } from "store/reducers/mobilePreviewSlice";
import { runValidator } from "utils";
import { cloneDeep } from "lodash";
const useProfileView = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const companyDetails = useSelector(
    (state) => state.mobilePreview.companyDetails
  );
  const profileId = useSelector((state) => state.user.profileId);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveModel, setSaveModel] = useState({
    address: companyDetails.companyAddress || "",
    description: companyDetails.companyDescription || "",
    email: companyDetails.email || "",
    profileImageBase64: companyDetails.profileImageBase64 || "",
    companyIndustryId: companyDetails.companyIndustryId || "",
    titleImageBase64: companyDetails.titleImageBase64 || "",
    username: companyDetails.userName || "",
    website: companyDetails.website || "",
    phoneNumber: companyDetails.phoneNumber || "",
    profileId,
    companyAddresses: companyDetails.companyAddresses || [
      {
        address: "",
        city: "",
        street: "",
        externalApplicationLink: "",
        zipCode: "",
        state: "",
      },
    ],
  });
  const [errors, setErrors] = useState({});
  const [industries, setIndustries] = useState([]);
  const INPUTS = {
    address: {
      rules: { required: false },
      errorMessage: "Bitte Adresse eingeben",
    },
    website: {
      rules: { required: false, url: true },
      errorMessage: "Bitte einen Link eingeben",
    },
    email: {
      rules: { required: false, email: true },
      errorMessage: "Bitte eine Email eingeben",
    },
    username: {
      rules: { required: false },
      errorMessage: "Bitte Unternehmen eingeben",
    },
  };

  useEffect(() => {
    getPossibleValues("industries").then((result) => {
      if (result) {
        const transformedIndustries = result.industries.map((industry) => ({
          title: industry.name,
          value: industry.id,
        }));
        setIndustries(transformedIndustries);
      } else {
        console.error("Industries data is not an array:", result);
      }
    });
  }, [location]);

  const validateAll = () => {
    let errors = {};
    for (let field in INPUTS) {
      let fieldErrors = runValidator(saveModel[field], INPUTS[field].rules);
      let errorMessage = INPUTS[field].errorMessage;
      if (fieldErrors.length > "0") {
        errors[field] = errorMessage;
      }
    }
    return errors;
  };
  const handleChangeModel = (name, value, isIndex, index) => {
    if (isIndex) {
      const payload = cloneDeep(saveModel);
      payload.companyAddresses[index][name] = value;
      setSaveModel(cloneDeep(payload));
    } else {
      const payload = { ...saveModel, [name]: value };
      setSaveModel({ ...payload });
      console.log(payload);
    }
    // setSaveModel({ ...saveModel, [name]: value });
  };
  const handleSubmit = () => {
    let errors = validateAll();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    const payload = { ...saveModel };
    setLoading(true);
    if (payload.titleImageBase64 === "") delete payload.titleImageBase64;
    if (payload.profileImageBase64 === "") delete payload.profileImageBase64;
    editCustomerProfile(payload).then((result) => {
      if (result?.status !== "error") {
        const newPayload = {
          ...companyDetails,
          userName: payload.username,
          companyIndustryId: payload.companyIndustryId,
          companyAddress: payload.address,
          companyAddresses: payload.companyAddresses,
          companyDescription: payload.description,
          company_logo:
            payload.profileImageBase64 || companyDetails.company_logo,
          company_title_image:
            payload.titleImageBase64 || companyDetails.company_title_image,
          ...payload,
        };
        dispatch(addDetails(newPayload));

        navigate("/");
      } else {
      }
      setLoading(false);
    });
  };
  return {
    navigate,
    model: { saveModel, handleChangeModel },
    handleSubmit,
    loading,
    errors,
    industries,
  };
};
export default useProfileView;
