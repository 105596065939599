import Logo from "assets/img/logo.png";
import InputField from "components/input-field";
import Button from "components/button";
import useLoginView from "./useLoginView";
import ApiMessage from "components/api-message";

const LoginView = () => {
  const {
    navigate,
    saveModel,
    handleLoginUser,
    loading,
    errorMessage,
    errors,
  } = useLoginView();
  const {
    model: { email, password },
    setModal,
  } = saveModel;

  return (
    <>
      <div className="authorization-container-view">
        <div className="logo-container">
          <img src={Logo} alt="Logo SURFjobs" />
        </div>
        <div className="container login-container">
          <div className="left-container" />
          <div className="right-container pt-6 pb-6">
            <h4 className="heading">ANMELDEN</h4>
            {errorMessage ? <ApiMessage errorMessage={errorMessage} /> : null}

            <div className="d-flex flex-col w-40 gap-1 justify-center align-center mt-30">
              <InputField
                onChange={(value) => setModal("email", value)}
                value={email}
                className="w-40"
                label="Email"
                placeholder=""
                isError={errors?.email}
                type="email"
              />
              <InputField
                onChange={(value) => setModal("password", value)}
                value={password}
                className="w-40"
                isError={errors?.password}
                label="Passwort"
                placeholder=""
                type="password"
                keyFunction={() => handleLoginUser()}
              />
              <p
                className="hrefLink color-primary"
                onClick={() => navigate("/forgot-password")}
              >
                Passwort vergessen?
              </p>
            </div>
            <div className="d-flex flex-col gap-1 mt-30">
              <Button
                loading={loading}
                onClick={handleLoginUser}
                title="LOG IN"
              />
              <p onClick={() => navigate("/signup")} className="hrefLink">
                Sie haben noch keinen Account?
                <span className="hrefLink color-primary ms-8">
                  Jetzt registrieren
                </span>
              </p>
            </div>
            <div className="d-flex flex-ro gap-1">
              <div>
                <a
                  className="color-black text-center size-2 pb-1"
                  href="https://www.surfjobs.at/impressum"
                  target="_blank"
                  rel="noreferrer"
                >
                  IMPRESSUM
                </a>
              </div>
              <div>
                <a
                  className=" color-black text-center size-2 text-black pb-1"
                  href="https://www.surfjobs.at/datenschutz"
                  target="_blank"
                  rel="noreferrer"
                >
                  DATENSCHUTZ
                </a>{" "}
              </div>
              <div>
                <a
                  className="color-black text-center size-2 text-black pb-1"
                  href="https://www.surfjobs.at/nutzungsbedingungen"
                  target="_blank"
                  rel="noreferrer"
                >
                  NUTZUNGSBEDINGUNGEN
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginView;
