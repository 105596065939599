const NotificationBar = ({ title, description }) => {
  return (
    <>
      <div className="notification-bar">
        {title ? <p className="title">{title}</p> : null}
        {description ? <p className="description">{description}</p> : null}
      </div>
    </>
  );
};
NotificationBar.defaultProps = {
  title: "",
  description: "",
};

export default NotificationBar;
