import Logo from "assets/img/logo.png";
import InputField from "components/input-field";
import Button from "components/button";
import ApiMessage from "components/api-message";
import usePasswordView from "./usePasswordView";

const PasswordView = () => {
  const {
    navigate,
    saveModel,
    handleResetPassword,
    loading,
    errorMessage,
    errors,
  } = usePasswordView();
  const {
    model: { email },
    setModal,
  } = saveModel;

  return (
    <>
      <div className="authorization-container-view">
        <div className="logo-container">
          <img src={Logo} alt="Logo SURFjobs" />
        </div>
        <div className="container login-container">
          <div className="left-container" />
          <div className="right-container pt-6 pb-6">
            <h4 className="heading">PASSWORT ZURÜCKSETZEN</h4>
            {errorMessage ? <ApiMessage errorMessage={errorMessage} /> : null}

            <div className="d-flex flex-col w-40 justify-center align-center mt-30">
              <InputField
                onChange={(value) => setModal("email", value)}
                value={email}
                className="w-40"
                label="Email"
                placeholder=""
                isError={errors?.email}
                type="email"
              />

              <div className="d-flex flex-col gap-2 mt-30">
                <Button
                  loading={loading}
                  onClick={handleResetPassword}
                  title="ZURÜCKSETZEN"
                />
                <p className="hreflink">
                  <span
                    onClick={() => navigate("/signup")}
                    className="hrefLink color-primary ms-8"
                  >
                    REGISTRIEREN
                  </span>

                  <span
                    onClick={() => navigate("/")}
                    className="hrefLink color-primary ms-8"
                  >
                    ANMELDEN
                  </span>
                </p>
              </div>
              <div className="d-flex flex-ro gap-1">
                <div>
                  <a
                    className="color-black text-center size-2 pb-1"
                    href="https://www.surfjobs.at/impressum"
                    target="_blank"
                    rel="noreferrer"
                  >
                    IMPRESSUM
                  </a>
                </div>
                <div>
                  <a
                    className=" color-black text-center size-2 text-black pb-1"
                    href="https://www.surfjobs.at/datenschutz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DATENSCHUTZ
                  </a>{" "}
                </div>
                <div>
                  <a
                    className="color-black text-center size-2 text-black pb-1"
                    href="https://www.surfjobs.at/nutzungsbedingungen"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NUTZUNGSBEDINGUNGEN
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PasswordView;
