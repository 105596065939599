import Logo from "assets/img/logo.png";
import { HomeIcon, SettingsIcon } from "assets/img/icons";
import ChangePasswordModal from "components/change-password-modal";
import useSidebar from "./useSidebar";
import DeleteProfileModal from "components/delete-profile-modal";
const Sidebar = () => {
  const {
    changePassword,
    deleteProfile,
    handleRouteClickHome,
    isHome,
    handleRouteClickSettings,
    isSettings,
  } = useSidebar();
  const { isOpen, toggleModal } = changePassword;
  const { isOpenDelete, toggleModalDelete } = deleteProfile;
  return (
    <>
      {isOpen ? (
        <ChangePasswordModal onClose={() => toggleModal(false)} />
      ) : null}
      {isOpenDelete ? (
        <DeleteProfileModal onClose={() => toggleModalDelete(false)} />
      ) : null}
      <div className="sidebar">
        <div className="sidebar-inner-container">
          <div className="d-flex flex-col gap-1">
            <div className="logo-container pb-5" onClick={handleRouteClickHome}>
              <img alt="Logo" src={Logo} />
            </div>
            <div
              onClick={handleRouteClickHome}
              className={
                isHome
                  ? "active-tab profile-route"
                  : "inactive-tab profile-route"
              }
            >
              <HomeIcon />
              <p className="text-white size-3">Start</p>
            </div>
            <div
              onClick={handleRouteClickSettings}
              className={
                isSettings
                  ? "active-tab profile-route"
                  : "inactive-tab profile-route"
              }
            >
              <SettingsIcon />
              <p className="text-white size-3">Einstellungen</p>
            </div>

            <div
              className={"inactive-tab profile-route"}
              onClick={() =>
                window.open(
                  "https://www.surfjobs.at/hilfe",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <p className="text-white size-3">Hilfe</p>
            </div>
          </div>
          <div className="d-flex flex-col justify-center align-center gap-3 pb-5">
            <div className="d-flex flex-col">
              <a
                className="text-center size-2 text-white pb-1"
                href="https://www.surfjobs.at/impressum"
                target="_blank"
                rel="noreferrer"
              >
                IMPRESSUM
              </a>
              <a
                className="text-center size-2 text-white pb-1"
                href="https://www.surfjobs.at/datenschutz"
                target="_blank"
                rel="noreferrer"
              >
                DATENSCHUTZ
              </a>
              <a
                className="text-center size-2 text-white pb-1"
                href="https://www.surfjobs.at/nutzungsbedingungen"
                target="_blank"
                rel="noreferrer"
              >
                NUTZUNGSBEDINGUNGEN
              </a>
              <h4 className="small-heading text-center pt-2">
                SURFjobs FlexCo
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
