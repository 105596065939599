import Axios from "axios";
const apiBaseURL = "https://api-management-surf.azure-api.net";


const axios = Axios.create({
  baseURL: apiBaseURL,
});

axios.CancelToken = Axios.CancelToken;
axios.defaults.headers.common["appkey"] = `appkey-frontend-company-customer-kzhxBVUuD8MFmLgZHWa5AER2rbeQptCPf46GNXnKw3qjcT7SJBXfJa6V3ZzTxkg9phQP5LbjCmMd2cS7YDKEutWNsvynr4R8HG`;
const session = localStorage.getItem("session");
if (session) {
  axios.defaults.headers.common["session"] = session;
}
axios.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response?.status === 500) {
    }
    // if user is unauthorized
    if (error.response?.status === 401) {
      await localStorage.removeItem("user");
      await localStorage.removeItem("token");
    }
    return error;
  }
);



export const updateSessionHeader = (sessionId) => {
  if (sessionId) {
    axios.defaults.headers.common["session"] = sessionId;
  } else {
    delete axios.defaults.headers.common["session"];
  }
};

export default axios;
