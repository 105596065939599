import { CancelIcon } from "assets/img/icons";
import InputField from "components/input-field";
import Button from "components/button";
import useOnclickOutside from "react-cool-onclickoutside";
import useResetPasswordModal from "./useResetPasswordModal";

const ResetPasswordModal = ({ onClose }) => {
  const ref = useOnclickOutside(onClose);

  const { saveModel, handleResetPassword, loading, errors, errorMessage } =
    useResetPasswordModal();

  const {
    model: { email },
    setModal,
  } = saveModel;

  return (
    <div className="modal-outer-container">
      <div ref={ref} className="modal">
        <div className="modal-header">
          <h3>Passwort zurücksetzen</h3>
          <div onClick={onClose} className="cursor-pointer">
            <CancelIcon />
          </div>
        </div>
        <div className="modal-body">
          <InputField
            onChange={(value) => setModal("email", value)}
            value={email}
            className="w-40"
            label="Email"
            placeholder="Email eingeben"
            isError={errors?.email}
            errorMessage={errors?.email}
            type="email"
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
        <div className="d-flex flex-col gap-2 mt-30">
          <Button
            loading={loading}
            onClick={handleResetPassword}
            title="ZURÜCKSETZEN"
          />
        </div>
      </div>
    </div>
  );
};

ResetPasswordModal.defaultProps = {
  onClose: () => {},
};

export default ResetPasswordModal;
