import { useRef, useState } from "react";
import MobilePreview from "components/mobile-preview";
import Button from "components/button";
import InputField from "components/input-field";
import TextArea from "components/text-area";
import UploadButton from "components/upload-button";
import useAddJob from "./useAddJob";
import { AddIcon } from "assets/icons";
import SelectBox from "components/select-box";
import VideoCustomControls from "components/video-custom-controls";
const AddJobView = () => {
  const videoRef = useRef(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const {
    navigate,
    model,
    handleChangeModel,
    addAddress,
    handleSubmit,
    loading,
    videoDetails,
    handleDeleteVideo,
    handleEditVideo,
    errors,
    isUploadButtonDisabled,
    isEditButtonDisabled,
    handleDeleteJobAddress,
    prevJobAddress,
    closeAddress,
    progress,
    industries,
    professions,
  } = useAddJob(fileSizeError);
  const { description, industryId, name, addresses, salary, professionId } =
    model;

  return (
    <>
      <div className="inner-container">
        <MobilePreview activeVideoId={videoDetails?.videoId} />
        <div className="detail-container">
          <div className="general-information d-flex flex-col gap-2">
            <div className="d-flex justify-space-between gap-2 align-center">
              {videoDetails ? (
                <>
                  <h3>JOB VIDEO BEARBEITEN</h3>
                  <Button
                    onClick={handleDeleteVideo}
                    type="outline"
                    title="&nbsp;LÖSCHEN&nbsp;"
                  />
                </>
              ) : (
                <h3>JOB VIDEO HINZUFÜGEN</h3>
              )}
            </div>
            <InputField
              value={name}
              isError={errors?.name}
              className="w-per-100"
              onChange={(value) => handleChangeModel("name", value)}
              placeholder="Neues Job Video"
              label="Video Titel"
              infoMessage="Der Titel für das Job Video sollte dem Lehrberuf entsprechen: z.B.: “Lehre Koch/Köchin”"
            />
            <UploadButton
              isError={errors?.videoDataBase64 || fileSizeError}
              onChange={(value) => handleChangeModel("videoDataBase64", value)}
              label={videoDetails ? "Video ersetzen" : "Job Video"}
              fileType="video/mp4"
              setFileSizeError={setFileSizeError}
              infoMessage="Laden Sie Job Videos hoch, die die verschiedenen Tätigkeitsbereiche und Ausbildungsangebote in Ihrem Unternehmen zeigen. Jugendliche können sich direkt auf diese Videos bewerben."
              progress={progress}
            />

            {videoDetails ? (
              <>
                <div className="video-container">
                  <video
                    ref={videoRef}
                    width={200}
                    controls={false}
                    autoPlay={false}
                    src={videoDetails.url}
                  />
                  <div className="video-details-container">
                    <p className="video-name">{videoDetails.jobName}</p>
                    <p className="video-company">{videoDetails.userName}</p>
                  </div>
                  <VideoCustomControls refElem={videoRef} />
                </div>
              </>
            ) : (
              <>
                <SelectBox
                  onChange={(value) => handleChangeModel("industryId", value)}
                  placeholder="Branche wählen"
                  label="Branche"
                  value={industryId}
                  options={industries}
                />
                <SelectBox
                  onChange={(value) => handleChangeModel("professionId", value)}
                  placeholder="Lehrberuf wählen"
                  label="Lehrberuf"
                  value={professionId}
                  options={professions}
                />
                <InputField
                  value={salary}
                  isError={errors?.salary}
                  className="w-per-100"
                  onChange={(value) => handleChangeModel("salary", value)}
                  label="Gehalt 1. Lehrjahr"
                  infoMessage="Geben Sie hier das Gehalt im ersten Lehrjahr an. Bitte nur den Betrag mit Punkt als Dezimaltrennzeichen ohne Währungszeichen eingeben."
                />
                {addresses &&
                  addresses.map((item, index) => (
                    <>
                      <div
                        key={`Job Video Standort - ${index}`}
                        className="d-flex flex-col gap-1"
                      >
                        <InputField
                          onChange={(value) =>
                            handleChangeModel("street", value, index, true)
                          }
                          className="w-per-100"
                          value={item.street}
                          placeholder="Adresse"
                          label="Standort"
                          infoMessage="Falls Sie eine Lehrstelle an mehreren Standorten anbieten, können Sie ganz einfach mehrere Standorte für ein Video hinzufügen (Sie müssen also nicht für jeden Standort ein eigenes Video hochladen).
                            Wenn Sie einen Link zu Ihrem Bewerbungsportal hinzufügen, werden die BewerberInnen direkt auf Ihr Bewerbungsportal geleitet und eine direkte Bewerbung über die App ist nicht möglich. Sie bekommen Ihre Bewerbungen dann direkt über Ihr eigenes Portal. Für jeden Standort kann ein eigener Link hinterlegt werden.
                            Falls kein Link hinterlegt ist, können Sich die Bewerber direkt mit 2 Klicks bei Ihnen bewerben und Sie erhalten die Bewerbungen direkt per Mail in das Postfach auf die in den „Allgemeinen Informationen“ hinterlegte E-Mail Adresse."
                        />
                        <div className="d-flex gap-1">
                          <InputField
                            onChange={(value) =>
                              handleChangeModel("city", value, index, true)
                            }
                            className="w-per-50"
                            value={item.city}
                            placeholder="Stadt"
                          />
                          <InputField
                            onChange={(value) =>
                              handleChangeModel("zipCode", value, index, true)
                            }
                            className="w-per-50"
                            value={item.zipCode}
                            isError={errors?.zipCode}
                            placeholder="PLZ"
                          />
                        </div>

                        <SelectBox
                          placeholder="Bundesland wählen"
                          className="w-per-100"
                          onChange={(value) =>
                            handleChangeModel("state", value, index, true)
                          }
                          options={regionOptions}
                        />
                        <InputField
                          className="w-per-100"
                          onChange={(value) =>
                            handleChangeModel(
                              "externalApplicationLink",
                              value,
                              index,
                              true
                            )
                          }
                          value={item.externalApplicationLink}
                          placeholder="Link zum Bewerbungsportal (optional)"
                        />
                        {index === 0 ? null : (
                          <p onClick={closeAddress} className="close-address">
                            LÖSCHEN
                          </p>
                        )}
                      </div>
                    </>
                  ))}
                <div
                  onClick={addAddress}
                  className="d-flex gap-1 align-center cursor-pointer"
                >
                  <AddIcon />
                  <p className="small-label">Weiteren Standort hinzufügen</p>
                </div>
                <TextArea
                  isError={errors?.description}
                  value={description}
                  label="Stellenbeschreibung"
                  onChange={(value) => handleChangeModel("description", value)}
                  rows={4}
                  infoMessage="Bitte geben Sie eine kurze und prägnante Beschreibung der Stelle ein. Diese sollte den Arbeitsplatz, die Hauptaufgaben und weitere relevante Aspekte des Lehrberufs umfassen. Vergessen Sie nicht die gesetzlich verpflichtende Gehaltsangabe. "
                />
                <div className="d-flex gap-1">
                  {videoDetails ? (
                    <Button
                      disabled={isEditButtonDisabled()}
                      loading={loading}
                      onClick={handleEditVideo}
                      title="UPDATE"
                    />
                  ) : (
                    <Button
                      disabled={isUploadButtonDisabled()}
                      loading={loading}
                      onClick={handleSubmit}
                      title="SPEICHERN"
                    />
                  )}

                  <Button
                    onClick={() => navigate("/")}
                    type="outline"
                    title="BEENDEN"
                  />
                </div>
                <div></div> {/* Spacing */}
              </>
            )}
          </div>
        </div>
        {videoDetails ? (
          <>
            <div className="d-flex flex-col gap-2" style={{ height: 600 }}>
              <SelectBox
                onChange={(value) => handleChangeModel("industryId", value)}
                placeholder="Branche wählen"
                label="Branche"
                value={industryId}
                options={industries}
              />
              <SelectBox
                onChange={(value) => handleChangeModel("professionId", value)}
                placeholder="Lehrberuf wählen"
                label="Lehrberuf"
                value={professionId}
                options={professions}
              />
              <InputField
                value={salary}
                isError={errors?.salary}
                className="w-per-100"
                onChange={(value) => handleChangeModel("salary", value)}
                label="Gehalt 1. Lehrjahr"
                infoMessage="Geben Sie hier das Gehalt im ersten Lehrjahr an. Bitte nur den Betrag mit Punkt als Dezimaltrennzeichen ohne Währungszeichen eingeben."
              />
              <div className="d-flex flex-col gap-2">
                <label className="input-label">Angegebene Standorte</label>
                {prevJobAddress && prevJobAddress.length === 0 ? (
                  <p className="text-sm">Kein Standort hinzugefügt!</p>
                ) : null}
                {prevJobAddress &&
                  prevJobAddress.map((item, index) => (
                    <div
                      key={`Video Address - ${item.externalApplicationLink} ${index}`}
                      className="address-bar"
                    >
                      <div className="d-flex flex-col gap-1">
                        <p className="address-heading">{item.address}</p>
                        <a
                          className="color-primary"
                          href={item.externalApplicationLink}
                        >
                          {item.externalApplicationLink}
                        </a>
                      </div>
                      <p
                        onClick={() => handleDeleteJobAddress(item.addressId)}
                        className="delete-address"
                      >
                        LÖSCHEN
                      </p>
                    </div>
                  ))}
              </div>
              {addresses &&
                addresses.map((item, index) => (
                  <>
                    {index === 0 ? null : (
                      <div
                        key={`Job Video Address - ${index}`}
                        className="d-flex flex-col gap-1"
                      >
                        <InputField
                          onChange={(value) =>
                            handleChangeModel("street", value, index, true)
                          }
                          className="w-per-100"
                          value={item.street}
                          placeholder="Adresse"
                          label="Standort"
                          infoMessage="Falls Sie eine Lehrstelle an mehreren Standorten anbieten, können Sie ganz einfach mehrere Standorte für ein Video hinzufügen (Sie müssen also nicht für jeden Standort ein eigenes Video hochladen).
                            Wenn Sie einen Link zu Ihrem Bewerbungsportal hinzufügen, werden die BewerberInnen direkt auf Ihr Bewerbungsportal geleitet und eine direkte Bewerbung über die App ist nicht möglich. Sie bekommen Ihre Bewerbungen dann direkt über Ihr eigenes Portal. Für jeden Standort kann ein eigener Link hinterlegt werden.
                            Falls kein Link hinterlegt ist, können Sich die Bewerber direkt mit 2 Klicks bei Ihnen bewerben und Sie erhalten die Bewerbungen direkt per Mail in das Postfach auf die in den „Allgemeinen Informationen“ hinterlegte E-Mail Adresse."
                        />
                        <div className="d-flex gap-1">
                          <InputField
                            onChange={(value) =>
                              handleChangeModel("city", value, index, true)
                            }
                            className="w-per-50"
                            value={item.city}
                            placeholder="Stadt"
                          />
                          <InputField
                            onChange={(value) =>
                              handleChangeModel("zipCode", value, index, true)
                            }
                            className="w-per-50"
                            value={item.zipCode}
                            placeholder="PLZ"
                          />
                        </div>
                        <SelectBox
                          placeholder="Bundesland wählen"
                          className="w-per-100"
                          onChange={(value) =>
                            handleChangeModel("state", value, index, true)
                          }
                          options={regionOptions}
                        />

                        <InputField
                          className="w-per-100"
                          onChange={(value) =>
                            handleChangeModel(
                              "externalApplicationLink",
                              value,
                              index,
                              true
                            )
                          }
                          value={item.externalApplicationLink}
                          placeholder="Link zum Bewerbungsportal (optional)"
                        />
                        <p onClick={closeAddress} className="close-address">
                          LÖSCHEN
                        </p>
                      </div>
                    )}
                  </>
                ))}
              <div
                onClick={addAddress}
                className="d-flex gap-1 align-center cursor-pointer"
              >
                <AddIcon />
                <p className="small-label">Weiteren Standort hinzufügen</p>
              </div>
              <TextArea
                isError={errors?.description}
                value={description}
                label="Stellenbeschreibung"
                onChange={(value) => handleChangeModel("description", value)}
                rows={4}
                infoMessage="Bitte geben Sie eine kurze und prägnante Beschreibung der Stelle ein. Diese sollte den Arbeitsplatz, die Hauptaufgaben und weitere relevante Aspekte des Lehrberufs umfassen. Vergessen Sie nicht die gesetzlich verpflichtende Gehaltsangabe."
              />
              <div className="d-flex gap-1">
                {videoDetails ? (
                  <Button
                    disabled={isEditButtonDisabled()}
                    loading={loading}
                    onClick={handleEditVideo}
                    title="UPDATE"
                  />
                ) : (
                  <Button
                    disabled={isUploadButtonDisabled()}
                    loading={loading}
                    onClick={handleSubmit}
                    title="SPEICHERN"
                  />
                )}

                <Button
                  onClick={() => navigate("/")}
                  type="outline"
                  title="BEENDEN"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
export default AddJobView;

export const regionOptions = [
  { title: "Oberösterreich", value: "Oberösterreich" },
  { title: "Niederösterreich", value: "Niederösterreich" },
  { title: "Salzburg", value: "Salzburg" },
  { title: "Wien ", value: "Wien " },
  { title: "Steiermark", value: "Steiermark" },
  { title: "Kärnten", value: "Kärnten" },
  { title: "Burgenland", value: "Burgenland" },
  { title: "Tirol", value: "Tirol" },
  { title: "Vorarlberg", value: "Vorarlberg" },
];
