import React, { useState } from "react";
import "./input-field.scss";
import { EyeClosedIcon, EyeIcon } from "assets/icons";

const InputField = (props) => {
  const {
    placeholder,
    type,
    label,
    orientation,
    className,
    inputStyle,
    onChange,
    value,
    disabled,
    isError,
    keyFunction,
    infoMessage,
  } = props;

  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <>
      <div className={`input-field-container ${orientation} ${className}`}>
        <div className="input-with-icon flex-row">
          {label ? <label className="input-label">{label}</label> : null}
          {infoMessage !== "" ? (
            <div className="icon-container">
              <span className="info-icon">&#9432;</span>
              <div className="tooltip">{infoMessage}</div>
            </div>
          ) : null}
        </div>
        <div className="input-with-icon-eye">
          <input
            autoComplete="off"
            value={value}
            min={1}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            style={inputStyle}
            className={`input-field ${isError ? "error-field" : ""}`}
            type={type === "password" && isPasswordVisible ? "text" : type}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === "Enter") keyFunction();
            }}
          />
          <div className="icon-container-eye">
            {type === "password" && (
              <span
                className={`toggle-password-visibility ${
                  isPasswordVisible ? "visible" : "hidden"
                }`}
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? (
                  <EyeClosedIcon width={12} />
                ) : (
                  <EyeIcon width={12} />
                )}
              </span>
            )}
          </div>
        </div>
        {isError ? <p className="error-message">{isError}</p> : null}
      </div>
    </>
  );
};

InputField.defaultProps = {
  placeholder: "",
  orientation: "column",
  label: null,
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  type: "text",
  className: "",
  icon: "",
  value: "",
  containerClass: "",
  min: 0,
  max: 200,
  step: 1,
  isError: false,
  inputStyle: {},
  errorMessage: "",
  keyFunction: () => {},
  infoMessage: "",
};

export default InputField;
