export const AddIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 24 24"
      >
        <g fill="#E1315B" fillRule="evenodd" clipRule="evenodd">
          <path d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12m10-8a8 8 0 1 0 0 16a8 8 0 0 0 0-16"></path>
          <path d="M13 7a1 1 0 1 0-2 0v4H7a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4z"></path>
        </g>
      </svg>
    </>
  );
};

export const BackIcon = ({ width = 9, height = 16 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H2V7H1V9Z"
          fill="#E1315B"
        />
      </svg>
    </>
  );
};

export const LocationIcon = ({ width = 42, height = 42 }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6458 43.75C29.4167 40.4792 27.0833 36.1667 27.0833 32.2917C27.0833 28.4792 29.0833 25 32.0833 22.9167H31.25V18.75H35.4167V21.3125C36.4583 21.0208 37.5 20.8333 38.5417 20.8333C38.8958 20.8333 39.25 20.8333 39.5833 20.8958V6.25H10.4167V43.75H22.9167V36.4583H27.0833V43.75H31.6458ZM31.25 10.4167H35.4167V14.5833H31.25V10.4167ZM18.75 39.5833H14.5833V35.4167H18.75V39.5833ZM18.75 31.25H14.5833V27.0833H18.75V31.25ZM18.75 22.9167H14.5833V18.75H18.75V22.9167ZM18.75 14.5833H14.5833V10.4167H18.75V14.5833ZM22.9167 10.4167H27.0833V14.5833H22.9167V10.4167ZM22.9167 18.75H27.0833V22.9167H22.9167V18.75ZM22.9167 31.25V27.0833H27.0833V31.25H22.9167ZM38.5417 25C34.5833 25 31.25 28.3542 31.25 32.2917C31.25 37.7292 38.5417 45.8333 38.5417 45.8333C38.5417 45.8333 45.8333 37.7292 45.8333 32.2917C45.8333 28.3542 42.5 25 38.5417 25ZM38.5417 35.0208C37.0833 35.0208 36.0417 33.7708 36.0417 32.5208C36.0417 31.0625 37.2917 30.0208 38.5417 30.0208C39.7917 30.0208 41.0417 31.25 41.0417 32.5208C41.25 33.7708 40 35.0208 38.5417 35.0208Z"
        fill="#E1315B"
      />
    </svg>
  );
};

export const PlayIcon = ({ width = 24, height = 24 }) => {
  return (
    <>
      <svg
        fill="%23fff"
        height={width}
        width={height}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <g id="play">
          <path d="M500.235,236.946L30.901,2.28C16.717-4.813,0.028,5.502,0.028,21.361v469.333c0,15.859,16.689,26.173,30.874,19.081l469.333-234.667C515.958,267.247,515.958,244.808,500.235,236.946z M42.694,456.176V55.879l400.297,200.149L42.694,456.176z" />
        </g>
      </svg>
    </>
  );
};

export const PauseIcon = ({ width = 24, height = 24 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="pause">
          <path
            id="Vector"
            d="M10 4H6V20H10V4Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M18 4H14V20H18V4Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export const FullScreenIcon = ({ width = 14, height = 14 }) => {
  return (
    <>
      <svg
        height={height}
        version="1.1"
        viewBox="0 0 14 14"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fillRule="evenodd"
          id="Page-1"
          stroke="none"
          strokeWidth={1}
        >
          <g
            fill="white"
            id="Core"
            transform="translate(-215.000000, -257.000000)"
          >
            <g id="fullscreen" transform="translate(215.000000, 257.000000)">
              <path
                d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export const EyeIcon = ({ width = 14, height = 14 }) => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
          stroke="gray"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
          stroke="gray"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export const EyeClosedIcon = ({ width = 14, height = 14 }) => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
          stroke="gray"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
